<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            ref="list"
            :expandRowByClick="true"
            @expandedRowsChange="handleExpandedRowsChange($event)"
            :expandedRowRender="true"
			:submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operations','class_rebate']"
			rowKey="id"
        >
            <template slot="right_btn">
                <a v-if="this.$store.state.page_auth.export" @click="report()">
					<a-button class="a_btn">导出</a-button>
				</a>
            </template>
            <template slot="class_rebate" slot-scope="data">
                {{ data.record.class_rebate == 'region' ? '区域' : '总部' }}
            </template>
            <template slot="expandedRowRender" slot-scope="data">
                <a-table
                    :columns="columns_sub"
                    :pagination="false"
                    :dataSource="data.record.list"
                    style="margin: 0"
                >
                    <!-- <template slot="classRebate" slot-scope="record">
                        {{ record == 'region' ? '区域' : '总部' }}
                    </template> -->
                    <template slot="operations" slot-scope="record">
                        <span v-auth="'change'">
                            <a @click="editPut(record)">编辑</a>
                        </span>
                        <a-divider v-auth="'change'" type="vertical" />
                        <a @click="go_detail(record)">查看</a>
                    </template>
                </a-table>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import TableList from "@/components/TableList";
import { getContractList, getGrantList, getCostType} from "@/api/cost";
let columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "合约名称",
        dataIndex: "title",
    },
    {
        title: "状态",
        dataIndex: "stataus_name",
    },
    {
        title: "签约业代所属部门",
        dataIndex: "path_name",
    },
    {
        title: "客户名称",
        dataIndex: "customer_name",
    },
    {
        title: "合约类型",
        dataIndex: "type_name",
    },
    {
        title: "返利口径",
        dataIndex: "class_rebate",
        scopedSlots: {
            customRender: "class_rebate"
        }
    },
];

let columns_sub = [
    {
        title: "投放周",
        dataIndex: "week",
    },
    {
        title: "申请人",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "path_name",
    },
    {
        title: "发放客户ID",
        dataIndex: "customer_id",
    },
    {
        title: "客户名称",
        dataIndex: "customer_name",
    },
    {
        title: "投放类型",
        dataIndex: "launch_type",
    },

    {
        title: "投放位置",
        dataIndex: "launch_position",
    },
    {
        title: "审批状态",
        dataIndex: "approval_status_name",
    },
    {
        title: "事由",
        dataIndex: "desc",
    },
    {
        title: "配送人",
        dataIndex: "give_user",
    },
    {
        title: "签收状态",
        dataIndex: "settle_status",
    },
    // {
    //     title: "返利口径",
    //     dataIndex: "class_rebate",
    //     scopedSlots: {
    //         customRender: 'classRebate',
    //     },
    // },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operations"
        }
    },
];

export default {
    name: "Index",
    components: {
        TableList
    },
    data() {
        return {
            get_table_list: getContractList,
            submit_preprocessing: {
                array_to_string: ['department_id','type_id']
            },
            columns,
            columns_sub,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "tree-select",
                        name: "type_id",
                        title: "投放类型",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "customer",
                        title: "发放客户",
                        placeholder: '输入客户名称、ID、地址',
                        options: {

                        }
                    },
                    {
                        type: "text",
                        name: "username",
                        title: "申请人",
                        placeholder: '输入工号、姓名、手机号',
                        options: {

                        }
                    },
					{
						type: 'month',
						name: 'grant_time',
						title: '发放时间',
						options: {}
					},
                    {
                        type: 'select',
                        name: 'class_rebate',
                        title: '返利口径',
                        list: [
                            {
                                key: 'region',
                                value: "区域"
                            },
                            {
                                key: 'headquarters',
                                value: "总部"
                            }
                        ],
                        options: {},
                    },
                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });

        // getType({
        //     data: {
        //         type: 'investment'
        //     }
        // }).then(res => {
        //     let type_list = this.$method.to_form_list(res.data.list, 'id', 'name')
        //     this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type_id', 'list', type_list)
        // })
        getCostType().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type_id', 'treeData', res.data.list)
        })
        
    },
    methods: {
        async handleExpandedRowsChange(e) {
            e.forEach(item => {
				let index = this.$refs.list.list.findIndex(v=>{
					return v.id == item
				})
                if (!this.$refs.list.list[index].list) {
                    this.get_grant_list(item)
                }
            })
        },
        get_grant_list(id) {
            getGrantList({
                data: {
                    contract_id: id,
                    ...this.$refs.list.seo_data
                }
            }).then(res => {
                let list = [];
                this.$refs.list.list.forEach((vo, index) => {
                    if (vo.id == id) {
                        vo.list = res.data.list
                    }
                    list.push(vo)
                })
                this.$refs.list.set_data('list', list)
            })
        },
        editPut(data){
            this.$keep_route.add(this.$options.name);
            this.$router.push({
                path:"./grant_edit",
                query:{
                    id:data.grant_id
                }
            })
        },
        go_detail(record) {
            this.$keep_route.add(this.$options.name);
            if (record.id) {
                this.$router.push('put_detail?id=' + record.id + '&grant_id=' + record.approval_id)
            }
        },  
		report() {
			this.$keep_route.add(this.$options.name);
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_cost_grant",
					...this.$refs.list.seo_data
				}
			});
		},
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>